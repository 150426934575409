import { useEffect, useRef } from "react";
import { useDraggable } from "@dnd-kit/core";
import styles from "./Drawer.module.css";
import DraggableCharm from "../DraggableCharm";

export default function Drawer({expanded, setScroll, charms}) {
  const MAX_DRAWER_HEIGHT_PERCENT = 0.5;
  const PULL_TAB_HEIGHT = "64px";

  const charmContainerRef = useRef(null);

  const { attributes, setNodeRef, listeners, transform, isDragging } = useDraggable({
    id: "charm-drawer",
  });

  const style = {
    "--transform": transform ? `${transform.y}px`: undefined,
    "--pull-tab-height": PULL_TAB_HEIGHT,
    "--height": `${MAX_DRAWER_HEIGHT_PERCENT * 100}vh`,
  }

  const handleScroll = () => {
    const position = charmContainerRef.current.scrollTop;
    setScroll(position);
  }

  useEffect(() => {
    charmContainerRef.current.addEventListener("scroll", handleScroll, { passive: true });
  });

  return (
    <div
      style={style}
      className={[styles.Drawer,
                  isDragging ? styles.dragging : null,
                  expanded ? styles.expanded : null].join(' ')
      }
    >
      <div ref={charmContainerRef} className={styles.charmContainer}>
        {
          charms.filter(c => c.stock > 0).toSorted((a, b) => a.name.localeCompare(b.name)).map(charm =>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <DraggableCharm charm={charm} selected={false}/>
            </div>
          )
        }
      </div>
      <div className={styles.handle} ref={setNodeRef} {...attributes} {...listeners} style={{"--height": PULL_TAB_HEIGHT}}>
        <div className={styles.pullBar} />
      </div>
    </div>
  )
}