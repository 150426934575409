import { Route, Routes } from 'react-router-dom';
import Home from "./pages/Home/Home";
import Book from "./pages/Book/Book";
import Gallery from "./pages/Gallery/Gallery";
import About from "./pages/About/About";
import Order from "./pages/Order/Order";

 export default function App() {
  return (
    <>
      <meta name="theme-color" content="#a38f8b"/>
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true"/>
      <link
        href="https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap"
        rel="stylesheet"/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/order" element={<Order/>}/>
        <Route path="/book" element={<Book/>}/>
        <Route path="/gallery" element={<Gallery/>}/>
        <Route path="/about" element={<About/>}/>
      </Routes>
    </>
  );
 }

