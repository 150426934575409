import { useState } from "react";
import styles from "./Popup.module.css";

export default function Popup({ message }) {
  const [open, setOpen] = useState(true);

  return (
    open && <div className={styles.container}>
      <div className={styles.box}>
        {message}
        <button
          className={styles.closeButton}
          onClick={() => setOpen(false)}
        >
          ok
        </button>
      </div>
    </div>
  )
}