import { useDraggable } from "@dnd-kit/core";
import styles from "./charm.module.css";
import CharmIcon from "./CharmIcon";

export default function DraggableCharm({charm, selected}) {
  const { attributes, setNodeRef, transform, listeners, isDragging } = useDraggable({
    id: charm.name,
  })

  const style = {
    touchAction: "none",
    transform: selected ? (transform ? `translate(${transform.x}px, ${transform.y}px)` : undefined) : undefined,
    position: selected ? "absolute" : "relative",
    top: charm.y + "px",
    left: charm.x + "px",
    zIndex: isDragging ? 3 : 1,
  }

  return (
    <div style={style} className={styles.Charm} ref={setNodeRef} {...attributes} {...listeners}>
      <CharmIcon charm={charm}/>
    </div>
  );
}