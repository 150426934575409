import Navbar from "../../components/Navbar/Navbar";
import "./About.css";
import us from "../../assets/us.jpeg";

export default function About() {
  return (
    <div id="about">
      <Navbar />
      <h2>about</h2>
      <div className="main-body">
        <div className="showcase-section">
          <div>
            <h2>what we do</h2>
            <span><b>popups</b> at boutiques and businesses <br /></span>
            <span><b>private parties</b> bachelorette, birthdays, wine nights & more<br /></span>
            <span><b>fairs and festivals</b></span>            
          </div>
          <h2></h2>
          <img src={us} alt={"owners Emily and Sarah at a popup"}/>
        </div>
      </div>
    </div>
  );
}