import { useDroppable} from "@dnd-kit/core";

export default function DrawerDropAreas(props) {
  const { setNodeRef: collapseDrawerAreaRef } = useDroppable({
    id: "hide-drawer-area",
  });

  const { setNodeRef: expandDrawerAreaRef } = useDroppable({
    id: "expand-drawer-area",
  });

  const style = {
    display: "grid",
    position: "absolute",
    inset: 0,
    gridTemplateRows: "0.5fr 1.5fr",
    zIndex: -1,
    flexBasis: "80%",
    height: "80%"
  }

  return (
    <div style={style}>
      <div ref={collapseDrawerAreaRef}/>
      <div ref={expandDrawerAreaRef}>
        { props.children }
      </div>
    </div>
  )
}