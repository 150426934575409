import { useState, useEffect } from "react";
import styles from "./Checkout/Checkout.module.css"
import Piece from "./Piece/Piece.js"

export default function Bag({cart, totalCost, removeFromCart, orderNotes, setOrderNotes}) {
  const [shippingCost, setShippingCost] = useState(10);
  const [sale, setSale] = useState(false);
  const [finalCost, setFinalCost] = useState(totalCost);

  useEffect(() => {
    fetch("/api/shippingCost", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "get",
    })
      .then(res => res.json())
      .then(json => setShippingCost(json.cost));

    fetch("/api/saleData", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "get",
    })
      .then(res => res.json())
      .then(json => {
        setSale(json.sale);
        if (json.sale) {
          const percentFactor = (100 - json.percentOff) / 100;
          setFinalCost(totalCost * percentFactor);
        } else {
          setFinalCost(totalCost);
        }
      });
  }, [totalCost]);

  return(
    <div style={{width: "100%"}}>
      <div className={styles.bag}>
        <div className={styles.bagItems}>
          {
            cart.map((piece, i) =>
              <Piece piece={piece} removePiece={() => removeFromCart(i)}/>
            )
          }
          <div style={{width: "100%", display: "flex", justifyContent: "space-between", marginTop: "16px"}}>
            <span>Subtotal</span>
            <span>
              <span className={sale ? styles.linethrough : null}>${totalCost.toFixed(2)}</span>
              {
                sale && <span className={styles.salePrice}>
                  {" $" + finalCost.toFixed(2)}
                </span>
              }
            </span>
          </div>
          <div style={{width: "100%", display: "flex", justifyContent: "space-between", marginTop: "16px"}}>
            <span>Shipping</span>
            <span>{shippingCost === 0 ? "free" : "$" + shippingCost.toFixed(2)}</span>
          </div>
          <div style={{width: "100%", display: "flex", justifyContent: "space-between", marginTop: "16px"}}>
            <span>Total</span>
            <span>${(finalCost + shippingCost).toFixed(2)}</span>
          </div>
          <br/>
          <div style={{
            display: "flex",
            gap: "4px"
          }}>
            Notes
            <input
              style={{
                backgroundColor: "#F1F1F1",
                border: "none",
                borderRadius: "12px",
                height: "18.5px",
                padding: "16px",
                flexBasis: "80%",
              }}
              value={orderNotes}
              onChange={e => setOrderNotes(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}