import { useState } from "react";
import { CaretDown, ShoppingBag } from "@phosphor-icons/react"
import { Elements, AddressElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Bag from "../Bag"
import styles from "./Checkout.module.css"
import PaymentForm from "./PaymentForm";
import PaymentConfirmed from "./PaymentConfirmed";

const stripePromise = loadStripe('pk_test_51Q2KV3DbYC566oJHSheBuROy5y0e7f2xSOGWoHIF5Klpci5pPHm7fxgqL0t9MTIbednKugLFt3oh0UeNYWuYWPN000kb9sTgBx');

const addressOptions = {
  mode: "shipping"
}

export default function Checkout({cart, open, totalCost, removeFromCart, closeCheckout, clientWidth, clientHeight, orderNotes, setOrderNotes}) {
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);

  let elementOptions = {
    mode: "payment",
    amount: 1000,
    currency: "usd",
    paymentMethodCreation: "manual",
    appearance: {
      theme: "flat",
      variables: {
        colorPrimary: "#B5C4C5",
        fontFamily: "Helvetica"
      }
    }
  }

  return (
    <div className={[styles.Checkout, open ? styles.open : null].join(" ")}>
      {
        !paymentConfirmed &&
        <button onClick={closeCheckout}>
          <CaretDown size={32} style={{color: "white"}}/>
        </button>
      }
      {
        paymentConfirmed ? <PaymentConfirmed successful={paymentSuccessful}/> :
        cart.length > 0 ? <Elements stripe={stripePromise} options={elementOptions}>
          <div className={styles.stagesContainer}>
            <div>
              <h2 className={styles.checkoutHeader}>my bag</h2>
              <Bag
                cart={cart}
                totalCost={totalCost}
                removeFromCart={removeFromCart}
                orderNotes={orderNotes}
                setOrderNotes={setOrderNotes}
              />
              <div className={styles.note}>
                Note: Jewelry will be created and shipped out within five days. All sales are final. Please check your email for communication about your order.
              </div>
            </div>
            <div>
            <h2 className={styles.checkoutHeader}>shipping address</h2>
            <div style={{backgroundColor: "white", width: "100%", boxSizing: "border-box", padding: "24px", borderRadius: "16px"}}>
              <AddressElement options={addressOptions}/>
            </div>
            </div>
            <div>
              <h2 className={styles.checkoutHeader}>payment</h2>
              <div style={{backgroundColor: "white", width: "100%", boxSizing: "border-box", padding: "24px", borderRadius: "16px"}}>
                <PaymentForm
                  cart={cart}
                  confirmPayment={() => setPaymentConfirmed(true)}
                  setPaymentSuccessful={setPaymentSuccessful}
                  clientWidth={clientWidth}
                  clientHeight={clientHeight}
                  orderNotes={orderNotes}
                  />
              </div>
            </div>
          </div>
        </Elements>
        : <div style={{display: "flex", flexDirection: "column", color: "white", alignItems: "center", gap: "28px"}}>
        <ShoppingBag size={38} style={{color: "white"}}/>
        <span>your bag is empty</span>
      </div>
      }
    </div>
  )
}
