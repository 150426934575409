import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./Gallery.css";

export default function Gallery() {
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    fetch("/api/gallery/images")
      .then(res => res.json())
      .then(json => setImageUrls(json.urls));
  }, []);

  return (
    <div id="gallery">
      <Navbar />
      <div id="image-grid">
        {
          imageUrls.map(url => <img alt={"gallery"} src={url}/>)
        }
      </div>
    </div>
  );
}