import { useState } from "react";
import '../../App.css';
import "./Book.css";
import Navbar from "../../components/Navbar/Navbar";

export default function Book() {
  const [location, setLocation] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const [otherInfo, setOtherInfo] = useState("");

  const [submitButtonText, setSubmitButtonText] = useState("send");

  // stores data to be displayed in event request form
  const fieldInfo = [
    {
      label: "location",
      placeholder: "an address, the name of your store, etc.",
      value: location,
      setValue: setLocation
    },
    {
      label: "date & time",
      placeholder: "if possible, provide backup date/ time",
      value: dateTime,
      setValue: setDateTime,
    },
    {
      label: "contact info",
      placeholder: "your phone number or email address",
      value: contactInfo,
      setValue: setContactInfo,
    },
    {
      label: "any other info",
      placeholder: "special requests, any questions, etc.",
      value: otherInfo,
      setValue: setOtherInfo,
    }
  ];

  function formSubmit(e) {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    fetch("/api/book-form", {
      headers: {
        "Content-Type": "application/json",
      },
      method: form.method,
      body: JSON.stringify(formJson)
    })
    .then(() => {
      setSubmitButtonText("thank you!");
    });
  }

  return (
    <div>
      <Navbar />
      <div id="book">
        <h2>request an event</h2>
        <div id="event-form">
          <form method="post" onSubmit={formSubmit}>
            {
              fieldInfo.map(
                info => (
                  <div>
                    <label>{info.label}
                      <input
                        name={info.label}
                        value={info.value}
                        onChange={e => info.setValue(e.target.value)}
                        placeholder={info.placeholder}
                      />
                    </label>
                  </div>
                )
              )
          }
            <button type="submit">{submitButtonText}</button>
          </form>
        </div>
        <div id="event-info">
          <h3>What does it cost to host an event?</h3>
          <p>Events at private parties with over 20 guests are free. Parties with under 20 guests are subject to a $75 deposit.</p>
          <h3>When can I host an event?</h3>
          <p>We're available for booking Monday-Friday after 5 PM, Saturdays limited and all day on Sundays.</p>
        </div>
      </div>
    </div>
  );
}
