import { ShoppingBag, CaretLeft, CaretRight, House } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import styles from "./Order.module.css";

export default function OptionsBar({ addToCart, selectPrevChain, selectNextChain, lengths, setLengthIndex, openCheckout, cartSize, clearCharms }) {
  return (
    <div className={styles.optionsBar}>
      <button onClick={addToCart} className={styles.barButton}>
        <span>add to cart</span>
      </button>
      <button onClick={clearCharms} className={styles.barButton}>
        <span>clear</span>
      </button>
      <Link to="/" style={{color: "white", textDecoration: "none"}}>
        <House size={32}/>
      </Link>
      <div className={styles.chainOptions}>
        <button onClick={selectPrevChain}>
          <CaretLeft size={24}/>
        </button>
        <select
          className={styles.lengthDropdown}
          onChange={e => setLengthIndex(e.target.selectedIndex)}
        >
          {
            lengths.map(length => 
              <option>{length}"</option>
            )
          }
        </select>
        <button onClick={selectNextChain}>
          <CaretRight size={24}/>
        </button>
      </div>
      <div className={styles.cartButton}>
        <button onClick={openCheckout}>
          <ShoppingBag size={32}/>
        </button>
        ({cartSize})
      </div>
    </div>
  );
}