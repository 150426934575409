import styles from "./Checkout.module.css";

export default function PaymentConfirmed({successful}) {
  return (
    <div>
      {
        successful ? <div>
          <h2 className={styles.checkoutHeader}>thank you!</h2>
          <span className={styles.message}>Your order is being processed and will be on its way soon.</span>
        </div>
        : <div>
          <h2 className={styles.checkoutHeader}>uh-oh</h2>
          <span className={styles.message}>There has been a problem handling your order. Please try again later.</span>
        </div>
      }
    </div>
  );
}