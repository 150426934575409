import { useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import styles from "./Checkout.module.css";

export default function PaymentForm({ cart, confirmPayment, setPaymentSuccessful, clientWidth, clientHeight, orderNotes }) {
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  async function submitOrder(event) {
    event.preventDefault();

    // validate form data
    const {error: submitError} = await elements.submit();
    if (submitError) {
      return;
    }

    setSubmitting(true);

    // create ConfirmationToken
    const {error, confirmationToken} = await stripe.createConfirmationToken({
      elements
    });

    if (error) {
      setPaymentSuccessful(false);
      confirmPayment();
    }

    fetch("/api/create-confirm-intent", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        confirmationTokenId: confirmationToken.id,
        items: cart,
        clientWidth: clientWidth,
        clientHeight: clientHeight,
        notes: orderNotes,
        email: email
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) {
          setPaymentSuccessful(false);
          confirmPayment();
        } else {
          setPaymentSuccessful(true);
          confirmPayment();
        }
      })
      .catch(e => {
        setPaymentSuccessful(false);
        confirmPayment();
      });
  }

  return (
    <form onSubmit={submitOrder}>
      <label>Email</label>
      <br/>
      <input
        style={{
          backgroundColor: "#F1F1F1",
          border: "none",
          borderRadius: "12px",
          height: "18.5px",
          padding: "16px",
          flexBasis: "80%",
          width: "90%",
          marginTop: "8px",
          marginBottom: "18px"
        }}
        value={email}
        onChange={e => setEmail(e.target.value)}
        type='email'
      />
      <PaymentElement/>
      <button className={styles.payButton}>
       {
          submitting ? "processing . . ." : "pay now"
        }
      </button>
    </form>
  );
}
